import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import { useLocalStorage } from "../hooks/useLocalStorage";
import React from "react";

declare var API: any;

type ShoppingCartProviderProps = {
  children: ReactNode;
};

type CartItem = {
  id: number;
  quantity: number;
  addVariationProductProductSelectedVariationData?: any;
};

type ProductCategoryLocaleTranslations = {
  [locale: string]: {
    name: string;
  };
};

export interface ProductCategory {
  id: number;
  name: string;
  state_id: number;
  display_color: string;
  locale_translations: ProductCategoryLocaleTranslations;
  order_by: number;
  web_state_id: number;
  cashregisterChildren: ProductCategory[];
}

export interface LocationProductDiscount {
  id: number;
  name: string;
  display_name: string;
  value: string;
  accumulates: number;
  starts_at: string;
  ends_at: string;
  indefinite: number;
}

export interface LocationProduct {
  translations: any;
  id: number;
  shortDescription: string;
  posProductCode: string;
  discounts: any;
  adjustments: any;
  price_net_with_discounts: string;
  price_with_discounts: string;
  product: any;
  deliveryTime: any;
  deliveryTimeWithOrdering: any;
  firstFileUrl: string;
  display_name: string;
  name: string;
  price_total: number;
  unit_shortname: string;
  productCategories: ProductCategory[];
  inStock: boolean;
}

type Order = {
  id: number | null;
  uuid: string | null;
  delivery_time: string | null;
};

type Person = {
  type?: string;
  id: number;
  firstname: string;
  surname: string;
  phone: string;
  email: string;
  language: string;
  country_code: string;
  organization_country_code: string;
  organization_name?: string;
  organization_reg_nr?: string;
  organization_KMKR_number?: string;
  organization_phone?: string;
  organization_email?: string;
  organization_address?: string;
  organization_historical_names?: string;
  organization_ariregister_status?: string;
  organization_ariregister_url?: string;
};

export type PaymentMethod = {
  id: number;
  name: string;
  value: string;
  sort_order: number;
  state: number;
  description: string;
  image: string;
  api_client_id: number;
  locale_translations: any;
  getMaksekeskusApiActive: {
    id: number;
    name: string;
    pivot: {
      payment_method_id: number;
      attribute_id: number;
      value: string;
    };
  };
  imageUrl: string;
  pivot: {
    person_id: number;
    payment_method_id: number;
    location_id: number;
  };
  api_client: {
    id: 2;
    type: string;
    status: string;
    name: null | string;
    comment: string;
    url: string;
    public_key: string;
    secret: string;
    client_id: string;
    environment: string;
    last_connection: string;
    created_at: string;
    updated_at: string;
    deleted_at: null | string;
    maksekeskus_payment_methods: Array<{
      id: number;
      payment_method_id: number | null;
      api_client_id: number;
      name: string;
      url: string;
      country: string;
      logo_url: string;
      type: string;
      created_at: string;
      updated_at: string;
      deleted_at: null | string;
    }>;
  };
};

interface DeliveryMethodPrice {
  id: number;
  delivery_method_id: number;
  name: string;
  price: number;
  price_neto: string;
  vat: number;
  weight_min: number;
  weight_max: number;
  sort_order: number;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
}

export type DeliveryMethod = {
  id: number;
  name: string;
  description: string;
  sort_order: number;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  type: string;
  deliveryMethodPrice: DeliveryMethodPrice;
  isParcelMachine: boolean;
  getDeliveryLocationsUrl: string;
  locale_translations: any;
};

type OrderDeliveryMethod = {
  id: number;
  order_id: number;
  delivery_method_id: number;
  delivery_method_price_id: number;
  address: string;
  city: string;
  postal_code: string;
  parcelMachineName: string | null;
  parcelDescription: string;
  buyer_is_receiver: number;
  receiverFirstname: string;
  receiverSurname: string;
  receiverEmail: string;
  receiverPhone: string;
  price_neto: number;
  price: number;
  vat: string;
  comment: string | null;
  delivery_method_name: string;
  delivery_method_type: string;
  delivery_method_description: string;
  delivery_method_price_name: string;
  weight: number;
  weight_min: number;
  weight_max: number;
  estimated_delivery_at: string;
  delivered_at: null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type ShoppingCartContext = {
  openCart: () => void;
  closeCart: () => void;
  toggleCart: () => void;
  getItemQuantity: (id: number) => number;
  increaseCartQuantity: (id: number, variationValues?: any) => void;
  decreaseCartQuantity: (id: number) => void;
  setCartQuantity: (id: number, quantity: number) => void;
  removeFromCart: (id: number) => void;
  getOrder: (buyerData?: Person) => void;
  setCartExpire: (value: Date) => void;
  cartQuantity: number;
  setCartExpireDate: () => void;
  totalCartSum: number;
  showCartExpire: boolean;
  setShowCartExpire: (value: React.SetStateAction<boolean>) => void;
  totalCartDiscount: number;
  totalCartSumConfirmed: number;
  productsLoaded: boolean;
  totalCartDiscountConfirmed: number;
  deliveryMehtodConfirmed: OrderDeliveryMethod | null;
  cartItems: CartItem[];
  order: Order;
  buyer: Person;
  STORAGE_EXP_LENGTH: number;
  setBuyer: (value: React.SetStateAction<Person>) => void;
  paymentMethods: PaymentMethod[];
  orderPaymentMethod: PaymentMethod | null;
  setOrderPaymentMethod: (
    value: React.SetStateAction<PaymentMethod | null>
  ) => void;
  deliveryMethods: DeliveryMethod[];
  confirmDeliveryData: (data: Object) => void;
  orderDeliveryMethod: OrderDeliveryMethod | null;
  setOrderDeliveryMethod: (
    value: React.SetStateAction<OrderDeliveryMethod | null>
  ) => void;
  locationProducts: LocationProduct[];
  setLocationProducts: (value: React.SetStateAction<LocationProduct[]>) => void;
  checkoutConfirm: () => void;
  clearData: () => void;
  setConfirmedOrder: (value: React.SetStateAction<Order>) => void;
  confirmedOrder: Order;
  cartItemsConfirmed: CartItem[];
};

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}
export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([]);
  const [orderDeliveryMethod, setOrderDeliveryMethod] =
    useState<OrderDeliveryMethod | null>(Object);
  const [orderPaymentMethod, setOrderPaymentMethod] =
    useState<PaymentMethod | null>(Object);

  const [showCartExpire, setShowCartExpire] = useState(false);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [confirmedOrder, setConfirmedOrder] = useState<Order>(Object);
  const [cartItemsConfirmed, setCartItemsConfirmed] = useState<CartItem[]>([]);
  const [totalCartSumConfirmed, setTotalCartSumConfirmed] = useState(0);
  const [totalCartDiscountConfirmed, setTotalCartDiscountConfirmed] =
    useState(0);
  const [deliveryMehtodConfirmed, setDeliveryMehtodConfirmed] =
    useState<OrderDeliveryMethod | null>(Object);

  const STORAGE_EXP_LENGTH = 10800000;
  const [cartExpire, setCartExpire] = useLocalStorage<Date>(
    "shopping-cart-expire",
    new Date(Date.now() + STORAGE_EXP_LENGTH)
  );
  const [cartItems, setCartItems] = useLocalStorage<CartItem[]>(
    "shopping-cart",
    []
  );
  const [buyer, setBuyer] = useLocalStorage<Person>("buyer", Object);
  const [locationProducts, setLocationProducts] = useLocalStorage<
    LocationProduct[]
  >("locationProducts", []);
  const [order, setOrder] = useLocalStorage<Order>("order", Object);

  const [totalCartSum, setTotalCartSum] = useState(
    cartItems.reduce((total, cartItem) => {
      const item = locationProducts.find((i) => i.id === cartItem.id);
      return total + (item?.price_total || 0) * cartItem.quantity;
    }, 0)
  );
  const [totalCartDiscount, setTotalCartDiscount] = useState(
    cartItems.reduce((total, cartItem) => {
      const item = locationProducts.find((i) => i.id === cartItem.id);

      const originalPrice = item?.price_total || 0;

      const discountedPrice = item?.price_with_discounts || 0;
      const discountAmount =
        (originalPrice - Number(discountedPrice)) * cartItem.quantity;

      return total + discountAmount;
    }, 0)
  );

  useEffect(() => {
    if (typeof API !== "undefined") {
      window
        // @ts-ignore
        .axios("/loadLocationProducts.json", {
          method: "GET",
          cache: "no-cache",
          headers: { "Content-Type": "application/json" },
        })
        .then((res: any) => {
          setLocationProducts((currItems) => {
            const newItems = [...res.data];
            return newItems;
          });
        })
        .then(() => setProductsLoaded(true));
    } else {
      fetch("/webstoreApi/loadLocationProducts.json", {
        method: "GET",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => {
          setLocationProducts((currItems) => {
            const newItems = [...res];
            return newItems;
          });
        })
        .then(() => setProductsLoaded(true));
    }
  }, []);

  useEffect(() => {
    setTotalCartSum(
      cartItems.reduce((total, cartItem) => {
        const item = locationProducts.find((i) => i.id === cartItem.id);
        return total + (item?.price_total || 0) * cartItem.quantity;
      }, 0)
    );

    setTotalCartDiscount(
      cartItems.reduce((total, cartItem) => {
        const item = locationProducts.find((i) => i.id === cartItem.id);

        const originalPrice = item?.price_total || 0;

        const discountedPrice = item?.price_with_discounts || 0;
        const discountAmount =
          (originalPrice - Number(discountedPrice)) * cartItem.quantity;

        return total + discountAmount;
      }, 0)
    );
  }, [cartItems]);

  if (
    new Date(cartExpire) <= new Date() &&
    cartItems.length > 0 &&
    !showCartExpire
  ) {
    // Check if shopping cart has 0 items, then return

    setShowCartExpire(true);
  }

  function clearData() {
    setCartExpireDate();
    setCartItems([]);
    setBuyer(Object);
    // setLocationProducts([])
    setOrder(Object);
    setOrderPaymentMethod(null);
    setOrderDeliveryMethod(null);
    setDeliveryMethods([]);
    setPaymentMethods([]);
  }

  function setCartExpireDate() {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH));
  }

  function checkoutConfirm() {
    // @ts-ignore
    window.axios
      .post("checkoutConfirm", {
        order: order,
        orderPaymentMethod: orderPaymentMethod,
        buyer: buyer,
        checkout_url: window.location.href,
      })
      .then((res: any) => {
        if (res.data.maksekeskusRedirect) {
          window.location.href = res.data.maksekeskusRedirect;
        } else {
          setConfirmedOrder(res.data.order);
          setCartItemsConfirmed(cartItems);
          setTotalCartSumConfirmed(totalCartSum);
          setTotalCartDiscountConfirmed(totalCartDiscount);
          setDeliveryMehtodConfirmed(orderDeliveryMethod);

          setCartItems([]);

          setOrder({ id: null, uuid: null, delivery_time: null });
        }
      })
      .catch((error: any) => {
        console.log(error);
        alert("Midagi läks valesti!");
      });
  }

  function confirmDeliveryData(data: Object) {
    setCartExpireDate();
    // @ts-ignore
    window.axios
      .post("confirmDeliveryData", {
        ...data,
        order: order,
        buyer: buyer,
      })
      .then((res: any) => {
        if (res.data.order) {
          setOrder(res.data.order);
        } else {
          setOrder(Object);
        }
        setOrderDeliveryMethod(res.data.orderDeliveryMethod);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  function getOrder(buyerData?: Person) {
    setCartExpireDate();
    // @ts-ignore
    window.axios
      .post("getOrder", {
        cartItems: cartItems,
        order: order,
        buyer: buyerData ? buyerData : buyer,
      })
      .then((res: any) => {
        if (res.data.order) {
          setOrder(res.data.order);

          let cartHasItemsThatNoLongerExist = false;
          cartItems.map((item) => {
            if (
              !res.data.order.order_products.find(
                (order_product: any) =>
                  item.id === order_product.location_product_id
              )
            ) {
              cartHasItemsThatNoLongerExist = true;
            }
          });
          if (cartHasItemsThatNoLongerExist) {
            setCartItems((currItems) => {
              return currItems.filter((item) => {
                return res.data.order.order_products.find(
                  (order_product: any) =>
                    item.id === order_product.location_product_id
                );
              });
            });
          }
        }
        if (res.data.paymentMethods) {
          setPaymentMethods(res.data.paymentMethods);
        }
        if (res.data.deliveryMethods) {
          setDeliveryMethods(res.data.deliveryMethods);
        }
        if (res.data.orderDeliveryMethod) {
          setOrderDeliveryMethod(res.data.orderDeliveryMethod);
        }
        if (res.data.locationProducts) {
          setLocationProducts((currItems) => {
            let newItems = [...currItems];
            res.data.locationProducts.map(
              (locationProduct: LocationProduct) => {
                if (
                  currItems.find((item) => item.id === locationProduct.id) ==
                  null
                ) {
                  newItems = [...newItems, locationProduct];
                } else {
                  newItems = newItems.map((item) => {
                    if (item.id === locationProduct.id) {
                      return { ...item, ...locationProduct };
                    } else {
                      return item;
                    }
                  });
                }
              }
            );
            return newItems;
          });
        }

        if (res.data.orderCompleted && res.data.order.finished_at === null) {
          setConfirmedOrder(Object);
          setCartItemsConfirmed([]);
          setCartItems([]);
          setOrder({ id: null, uuid: null, delivery_time: null });

          return;
        }

        if (res.data.orderCompleted) {
          setConfirmedOrder(res.data.order);
          setCartItemsConfirmed(cartItems);
          if (res.data.orderPaymentMethod) {
            setOrderPaymentMethod(res.data.orderPaymentMethod);
          }

          setCartItems([]);
          setOrder({ id: null, uuid: null, delivery_time: null });
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  );

  const openCart = () => setIsOpen(true);
  const closeCart = () => setIsOpen(false);
  function getItemQuantity(id: number) {
    return cartItems.find((item) => item.id === id)?.quantity || 0;
  }
  function toggleCart() {
    setIsOpen((curr) => !curr);
  }
  function increaseCartQuantity(id: number, variationValues?: any) {
    setCartExpireDate();
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id) == null) {
        return [
          ...currItems,
          {
            id,
            quantity: 1,
            addVariationProductProductSelectedVariationData: variationValues,
          },
        ];
      } else {
        return currItems.map((item) => {
          console.log(item);

          if (item.id === id) {
            return {
              ...item,
              quantity: item.quantity + 1,
              addVariationProductProductSelectedVariationData: variationValues,
            };
          } else {
            return item;
          }
        });
      }
    });
    openCart();
  }
  function decreaseCartQuantity(id: number) {
    setCartExpireDate();
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id)?.quantity === 1) {
        return currItems;
        // return currItems.filter(item => item.id !== id)
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
  }
  function setCartQuantity(id: number, quantity: number) {
    setCartExpireDate();
    setCartItems((currItems) => {
      if (quantity <= 0) {
        return currItems.filter((item) => item.id !== id);
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: quantity };
          } else {
            return item;
          }
        });
      }
    });
  }
  function removeFromCart(id: number) {
    setCartExpireDate();
    setCartItems((currItems) => {
      return currItems.filter((item) => item.id !== id);
    });
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        setShowCartExpire,
        productsLoaded,
        setCartExpire,
        getItemQuantity,
        showCartExpire,
        setCartExpireDate,
        increaseCartQuantity,
        decreaseCartQuantity,
        setCartQuantity,
        removeFromCart,
        openCart,
        closeCart,
        toggleCart,
        cartItems,
        STORAGE_EXP_LENGTH,
        cartQuantity,
        totalCartSum,
        totalCartDiscount,
        deliveryMehtodConfirmed,
        totalCartDiscountConfirmed,
        totalCartSumConfirmed,
        locationProducts,
        setLocationProducts,
        clearData,
        buyer,
        setBuyer,
        order,
        getOrder,
        deliveryMethods,
        orderDeliveryMethod,
        setOrderDeliveryMethod,
        confirmDeliveryData,
        paymentMethods,
        orderPaymentMethod,
        setOrderPaymentMethod,
        checkoutConfirm,
        setConfirmedOrder,
        confirmedOrder,
        cartItemsConfirmed,
      }}
    >
      {children}
      <ShoppingCart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  );
}
