import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage3Avaleht from "./pages/Webpage3Avaleht";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNzE3NzM5NTlmNmYyNTdkNWM5MzAyYzE0ZjE1MzY0ZWNmOWIxMGFmZmYwOGEzYmUwY2RmNDA1YTZlMjc4ZmRlMzQ0YzkzNmFkMWNkZThlY2EiLCJpYXQiOjE3MjQwNjQxMTUuMjY0MzI5LCJuYmYiOjE3MjQwNjQxMTUuMjY0MzM3LCJleHAiOjE3MjQxNTA1MTUuMjYwMTU5LCJzdWIiOiI2MyIsInNjb3BlcyI6W119.gj7x7i64zSvyesUgQajPtDYodltK4BZbEzA-plnwAdETRr4Ck8ZTnlHkv6nIOCK3W8siWgJFhBs_29q69_OHo9bP_M6nuSHNzAeg8Pb8mI7iZNSZM-RHy0OrTEvoyEeCeF3fm_2pPawGUBGZdoThTUv3FOQDBBYm9UJNWe1C_JL7S8SYP-VK8ebPBfshOIuavzKmirqm8ceSSQ08Ywgd-RcQenDdESCp5hbyPWwmQcrcbcjBKOo6AO0bcQmxAK2yBF6ELyidSPAocP3JdkTMBF1EiZxK1ohdt89IiFax9q-N1I0J9_CNIQfiideK-wIbYPvpT0pksP2uapiF-ABNb-j2rnGQYnlWxUyNmaS_ZoFJ3FaXz4AUkCb7v8CehLXI926mMzHkx77gWirmTqmay8DRpZ_qIZtBLzp0ItKCfE7K0FnWCtV7iv4XQ_2gW5UvP4PiStDEzoZyMtJud1FWV54R_d8RTs_rwT-GqEvaLJ-oHkWAcczkO7mikxczpQsxiR3H3A2KXCeQHG8r-9_ZWoxc8nJen4XCnArDrB0uDyVTxKFVhnPbvl_1K6t7wVQwY0aBezfcwGZ_0L3D0VINRp3T6aGZGuRc1HtKvf4zlwcbE5Vn50NYG4lqheowsKIR7H0GpmaG6gBsdNqNK_UrW4RQOXn3fLyus8hTPOyEUXU',
    'Access-api-key': "eyJpdiI6Imczbm9RTWFzTDdoZUtUNUFGbnFyVnc9PSIsInZhbHVlIjoicTdxd1FCcmhLZkxtSHYya1cvWWQ0UT09IiwibWFjIjoiZmUxOGVjMzE0OWIwZmY1MjA1Mzg5ZDRlNmZmNjFhYjM1YWJiZTFlMGIyNjkxZTk2YTE1OWVlOGQ5ZGU4NGU1ZiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Im1kR0RtMjExRzh5Yzg3YmFUZmNtMUE9PSIsInZhbHVlIjoiQmozeW5YTnI0bnpsY2VPNEEwQWZ2dz09IiwibWFjIjoiMzAyOTI5OWNhNDNmNDJjZDY4MmM4YTc5M2MyOTY5MzBkMTEwMTU2YTRkMTc1NjZmMWVkMjhlOWMwOTY1ZjVkMCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage3Avaleht />} exact />;
                                                                
                                                                <Route path="*" element={<Webpage3Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}