import { useState, useEffect, useRef } from "react";
import CategoriesComponent from "../Categories/CategoriesComponent";
import Pagination from "@mui/material/Pagination";
import usePageLanguage from "../hooks/usePageLanguage";
import "./ProductsComponent.css";
import {
  useShoppingCart,
  LocationProduct,
} from "../context/ShoppingCartContext";
import { useAppSelector } from "../../../store/hooks";
import ProductComponent from "./ProductComponent";
import ProductsSortComponent from "./ProductsSortComponent";
import { ProductsComponent as ProductsComponent_ } from "../interfaces/base";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import RenderChildren from "../RenderChildren";
import { Search } from "@mui/icons-material";
import React from "react";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);
declare var API: any;

export default function ProductsComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: ProductsComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchVisible, setSearchVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { locationProducts, setLocationProducts } = useShoppingCart();
  const lang = usePageLanguage();
  var category = Number(searchParams.get("category"));
  var query = searchParams.get("s");

  const [baseLocationProducts, setBaseLocatonProducts] = useState(
    locationProducts.filter((product) => product.product.only_variation === 0)
  );

  const [filteredProducts, setFilteredProducts] =
    useState<LocationProduct[]>(baseLocationProducts);

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as ProductsComponent_;

  const [productCategoryId, setProductCategoryId] = useState<number | null>(
    category
  );

  const [search, setSearch] = useState<string | null>(query);

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(30);
  const [sortFilter, setSortFilter] = useState<string>(
    component.attributes.default_sort
      ? component.attributes.default_sort
      : "name_asc"
  );

  const handlePageChange = (event: any, value: number) => {
    setPage(value);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const languageTranslations = {
    et: {
      search: "Otsi",
    },
    en: {
      search: "Search",
    },
  };

  useEffect(() => {
    if (!searchParams.get("s")) {
      setSearch(null);
    }

    // @ts-ignore
    if (!searchParams.get("category")) {
      changeCategoryId(null);
    }
  }, [searchParams]);

  switch (sortFilter) {
    case "price_asc":
      filteredProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        return a.price_total - b.price_total;
      });
      break;
    case "price_desc":
      filteredProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        return b.price_total - a.price_total;
      });
      break;
    case "name_asc":
      filteredProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        const nameA = (a.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        const nameB = (b.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      break;
    case "name_desc":
      filteredProducts.sort(function (a: LocationProduct, b: LocationProduct) {
        const nameA = (a.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        const nameB = (b.translations?.[lang].name || "").toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }

        // names must be equal
        return 0;
      });
      break;

    default:
      break;
  }

  const handleSearch = (search) => {
    let searchTerm;

    if (inputRef.current) {
      searchTerm = inputRef.current.value;
    }

    if (!searchTerm || searchTerm === "") {
      setProductCategoryId(null);
      setPage(1);

      setFilteredProducts(baseLocationProducts);
      setSearchParams();
      return;
    }

    setSearchParams({ s: searchTerm });

    setProductCategoryId(null);
    setPage(1);

    setSearch(searchTerm);

    const matchingLocationProducts = baseLocationProducts.filter(function (
      locationProduct
    ) {
      return locationProduct.translations[lang].name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setFilteredProducts(matchingLocationProducts);

    setPageCount(Math.ceil(matchingLocationProducts.length / perPage));
  };

  useEffect(() => {
    if (productCategoryId) {
      setSearchParams({ category: productCategoryId.toString() });

      const matchingLocationProducts = baseLocationProducts.filter(function (
        locationProduct
      ) {
        return locationProduct.productCategories.find(function (
          productCategory
        ) {
          return productCategory.id === productCategoryId;
        });
      });

      setPageCount(Math.ceil(matchingLocationProducts.length / perPage));
      setFilteredProducts(matchingLocationProducts);
    } else if (!productCategoryId && !search) {
      setSearchParams();
    }
  }, [productCategoryId]);

  const [pageCount, setPageCount] = useState(
    Math.ceil(filteredProducts.length / perPage)
  );

  useEffect(() => {
    if (search && baseLocationProducts.length > 0) {
      setSearchParams({ s: search });
      setSearch(search);

      const matchingLocationProducts = baseLocationProducts.filter(function (
        locationProduct
      ) {
        return locationProduct.translations[lang].name
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setFilteredProducts(matchingLocationProducts);

      setPageCount(Math.ceil(matchingLocationProducts.length / perPage));

      if (inputRef.current) {
        inputRef.current.style.opacity = "1";
        inputRef.current.style.display = "inline-block";
        inputRef.current.focus();
      }

      setSearchVisible(true);

      if (search !== inputRef.current?.value && inputRef.current) {
        inputRef.current.value = search;
      }
    } else if (!productCategoryId && !search) {
      setSearchParams();
    }
  }, [search, baseLocationProducts, lang]);

  const startOffset = (page - 1) * perPage;
  const endOffset = startOffset + perPage;

  useEffect(() => {
    if (productCategoryId) {
      const matchingLocationProducts = baseLocationProducts.filter(function (
        locationProduct
      ) {
        return locationProduct.productCategories.find(function (
          productCategory
        ) {
          return productCategory.id === productCategoryId;
        });
      });
      setPageCount(Math.ceil(matchingLocationProducts.length / perPage));
    } else {
      setPageCount(Math.ceil(baseLocationProducts.length / perPage));
    }
  }, [page, perPage]);

  const changeCategoryId = function (categoryId: null | number) {
    if (categoryId) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setSearchParams({ category: categoryId.toString() });
    } else {
      setSearchParams();
    }
    setProductCategoryId(categoryId);
    setPage(1);
  };

  useEffect(() => {
    // Update visibility based on focus
    if (inputRef.current) {
      if (document.activeElement === inputRef.current) {
        inputRef.current.style.display = "inline-block";
        inputRef.current.style.opacity = "1";
      } else {
        setSearchVisible(false);
        inputRef.current.style.display = "none";
        inputRef.current.style.opacity = "0";
      }
    } else {
      setSearchVisible(false);
    }
  }, [searchVisible]);

  useEffect(() => {
    // Reset everything, when there is no search params
    if (!searchParams.get("s") && !searchParams.get("category")) {
      setProductCategoryId(null);
      setFilteredProducts(baseLocationProducts);
      setPage(1);
      setPageCount(Math.ceil(baseLocationProducts.length / perPage));
    }
  }, [searchParams]);

  return (
    <>
      <Div
        id={id.toString()}
        key={id}
        data-style={attributes && attributes.styles}
        className={
          attributes &&
          attributes.classes +
            (attributes.classes != "container" &&
            attributes.classes != "container-fluid"
              ? " builder-component builder-component__products"
              : "") +
            (editorId === id ? " builder-component--active" : "")
        }
        data-component-type="section"
      >
        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations}
        />

        <div className="products_container">
          <div className="row">
            {component.attributes.category_visible &&
              (component.attributes.category_visible === true ||
                component.attributes.category_visible === 1) && (
                <>
                  <div className="col-lg-3 sticky">
                    <CategoriesComponent
                      depthLevel={attributes.depth_level}
                      productCategoryId={productCategoryId}
                      setProductCategoryId={changeCategoryId}
                    />
                  </div>
                </>
              )}

            {component.attributes.category_visible === undefined && (
              <>
                <div className="col-lg-3 sticky">
                  <CategoriesComponent
                    depthLevel={attributes.depth_level}
                    productCategoryId={productCategoryId}
                    setProductCategoryId={changeCategoryId}
                  />
                </div>
              </>
            )}

            <div
              className={
                component.attributes.category_visible === true ||
                component.attributes.category_visible === undefined ||
                component.attributes.category_visible === 1
                  ? "col-lg-9"
                  : "col-lg-12"
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "15px",
                }}
                className="margin-top-15"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <div
                    className={
                      searchVisible === true ? "search-container-product" : ""
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <input
                      type="text"
                      ref={inputRef}
                      className="form-control product-search-container-input"
                      placeholder={languageTranslations[lang]?.search}
                      style={{
                        width: "100%",
                        maxWidth: "150px",
                        marginRight: "10px",
                        border: "none",
                        opacity: "0",
                        display: "none",
                      }}
                      onFocus={() => {
                        if (inputRef.current) {
                          inputRef.current.style.opacity = "1";
                          inputRef.current.style.display = "inline-block";
                        }
                      }}
                      onBlur={() => {
                        if (inputRef.current) {
                          if (inputRef.current.value) {
                            setSearchVisible(true);
                            inputRef.current.style.opacity = "1";
                            inputRef.current.style.display = "inline-block";
                          } else {
                            setSearchVisible(false);
                            inputRef.current.style.opacity = "0";
                            inputRef.current.style.display = "none";
                          }
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSearch(search);
                        }
                      }}
                    />

                    <Search
                      style={{ fontSize: "20px" }}
                      onClick={(e) => {
                        if (inputRef.current) {
                          setSearchVisible(true);
                          inputRef.current.style.opacity = "1";
                          inputRef.current.style.display = "inline-block";
                          inputRef.current.focus();
                        }
                        e.preventDefault();
                        e.stopPropagation();
                        handleSearch(search);
                      }}
                    />
                  </div>
                </div>
                <ProductsSortComponent
                  sortFilter={sortFilter}
                  setSortFilter={setSortFilter}
                  perPage={perPage}
                  setPerPage={setPerPage}
                />
              </div>

              <div className="card itprCard" id="productsPanel">
                <div className="card-body">
                  {filteredProducts
                    .slice(startOffset, endOffset)
                    .map((locationProduct: any) => {
                      return (
                        <ProductComponent
                          key={locationProduct.id}
                          locationProduct={locationProduct}
                        />
                      );
                    })}
                </div>
                {pageCount > 1 && (
                  <div className="card-footer">
                    <Pagination
                      className="MuiPagination-ul-center "
                      shape="rounded"
                      count={Math.ceil(filteredProducts.length / perPage)}
                      page={page}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Div>
    </>
  );
}
