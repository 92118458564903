import { useShoppingCart } from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import { CartItemRow } from "../ShoppingCart/CartItemRow";
import { pf } from "../utilities/helpers";
import React from "react";

const languageTranslations = {
  et: {
    products: "TOOTED",
    total: "KOKKU",
    sum: "Summa",
    discount: "Kohandus",
    delivery: "Tarne",
  },
  en: {
    products: "PRODUCTS",
    total: "TOTAL",
    sum: "Sum",
    discount: "Adjustment",
    delivery: "Delivery",
  },
};

export default function CheckoutProductsPanel() {
  const { cartItems, orderDeliveryMethod, totalCartSum, totalCartDiscount } =
    useShoppingCart();

  const deliveryPrice =
    orderDeliveryMethod !== null && orderDeliveryMethod.price
      ? Number(orderDeliveryMethod.price)
      : 0;

  const locale = usePageLanguage();

  return (
    <div className="panel" id="checkoutProductsPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary ">1</span>
          <span className="panel-title-text">
            {languageTranslations[locale]?.products}
          </span>
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-xs-12 padding-top-20">
            <div
              className="table"
              style={{ fontSize: "14px", width: "100%", paddingTop: "5px" }}
            >
              <div>
                {cartItems.map((item) => (
                  <CartItemRow key={item.id} {...item} />
                ))}
              </div>
            </div>
          </div>
          {orderDeliveryMethod?.id || totalCartDiscount ? (
            <div className="col-xs-12 padding-top-20">
              <div style={{ fontWeight: "400", fontSize: "14px" }}>
                {languageTranslations[locale]?.sum}
                <span className="pull-right">
                  <span className="cartTotal">{pf(totalCartSum)}</span> &euro;
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          {totalCartDiscount ? (
            <div className="col-xs-12 ">
              <div
                className="discount-price-color"
                style={{ fontWeight: "400", fontSize: "14px" }}
              >
                {languageTranslations[locale]?.discount}
                <span className="pull-right">
                  <span className="cartTotal">
                    {pf(Number(-totalCartDiscount))}
                  </span>{" "}
                  &euro;
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          {orderDeliveryMethod?.id && (
            <div className="col-xs-12 ">
              <div style={{ fontWeight: "400", fontSize: "14px" }}>
                {languageTranslations[locale]?.delivery}
                <span className="pull-right">
                  <span className="cartTotal">
                    {orderDeliveryMethod ? pf(deliveryPrice) : pf(0)}
                  </span>{" "}
                  &euro;
                </span>
              </div>
            </div>
          )}
          <div className="col-xs-12 ">
            <div style={{ fontWeight: "400", fontSize: "18px" }}>
              {languageTranslations[locale]?.total}
              <span className="pull-right">
                <span className="cartTotal">
                  {pf(
                    totalCartSum -
                      totalCartDiscount +
                      (orderDeliveryMethod ? deliveryPrice : 0)
                  )}
                </span>{" "}
                &euro;
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
